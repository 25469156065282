<template>
  <v-row>
    <v-col cols="12">
      <v-card class="border-top-blue" tile>
        <v-card-title
          class="text-h5 app-dark-gray--text font-weight-light py-3"
        >
          <v-icon left>mdi-receipt</v-icon>
          <span>{{ $t('purchases.myPurchases') }}</span>
        </v-card-title>
      </v-card>
      <div class="mt-3" v-if="purchases.length > 0">
        <purchases :purchases="purchases"></purchases>
        <v-pagination
          class="mt-3"
          v-model="page"
          :length="pagesLength"
          :total-visible="7"
          color="app-blue"
          @input="loadPurchases"
        ></v-pagination>
      </div>
      <div v-else class="text-center py-4">
        <h1 class="heading app-dark-gray--text font-weight-light">
          {{ $t('purchases.noPurchasesFound') }}
        </h1>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import dailyCronMixin from '../../mixins/daily.cron.mixin';
import purchasesHttpService from '../../services/store/purchases/purchases.http.service';
import purchaseTypes from '../../enums/purchase.types';
import Purchases from '../../components/store/purchases/Purchases.vue';
import amountFormatService from '../../services/shared/amount.formatter.service';

export default {
  name: 'purchases-page',
  components: {
    Purchases
  },
  mixins: [dailyCronMixin],
  data() {
    return {
      purchases: [],
      totalCount: 0,
      page: 1,
      pagesLength: 1,
      limit: 25
    };
  },
  async created() {
    await this.loadPurchases(this.page);
    this.pagesLength = Math.ceil(this.totalCount / this.limit);
  },
  methods: {
    mapPurchase(purchase) {
      const { data } = purchase;

      if (
        purchase.type === purchaseTypes.avatar ||
        purchase.type === purchaseTypes.board
      ) {
        const value = data.gold || data.gems;
        const currency = data.gold ? 'Gold' : 'Gems';

        return {
          id: purchase.id,
          createdAt: purchase.createdAt,
          title: this.$i18n.t(`purchases.${purchase.type}.title`),
          text: this.$i18n.t(`purchases.${purchase.type}.text`, {
            value,
            currency
          })
        };
      }

      return {
        id: purchase.id,
        createdAt: purchase.createdAt,
        title: this.$i18n.t(`purchases.${purchase.type}.title`),
        text: this.$i18n.t(`purchases.${purchase.type}.text`, {
          gems: data.gems * data.quantity,
          amount: amountFormatService.formatDisplayValue(
            data.price * data.quantity
          )
        })
      };
    },
    async loadPurchases(number) {
      const skip = (number - 1) * this.limit;

      const { result } = await purchasesHttpService.index({
        limit: this.limit,
        skip
      });

      this.purchases = result.items.map(p => this.mapPurchase(p));
      this.totalCount = result.totalCount;
    }
  }
};
</script>
