<template>
  <v-list two-line>
    <template v-for="(purchase, index) of purchases">
      <v-list-item :key="purchase.id">
        <v-list-item-content>
          <v-list-item-title>
            <h3 class="subtitle">
              {{ purchase.title }}
            </h3>
          </v-list-item-title>
          <v-list-item-subtitle>
            <span v-html="purchase.text"></span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <span class="text-body-2">
            {{ purchase.createdAt | localDateTimeFormat }}
          </span>
        </v-list-item-action>
      </v-list-item>

      <v-divider v-if="purchases.length - 1 !== index" :key="index"></v-divider>
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'purchases',
  props: {
    purchases: {
      type: Array,
      required: true
    }
  }
};
</script>
